import React, { useEffect, useState } from 'react'
import './UserTray.css'
import { MDBDropdown, MDBDropdownItem, MDBDropdownMenu, MDBDropdownToggle, MDBIcon, MDBListGroup, MDBListGroupItem, MDBNavbarItem, MDBRipple } from 'mdb-react-ui-kit'
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectTokenData, selectUserMode } from '../../../redux/authSlice';
import appConfig from '../../../configs/App.config';

const UserTray = ({ handleLogoutClick }) => {
    const userData = useSelector(selectTokenData);
    const [userName, setUserName] = useState('');
    const [userType, setUserType] = useState('');
    const [profileKey, setProfileKey] = useState('');
    const [isResident, setIsResident] = useState(false);
    const userMode$ = useSelector(selectUserMode);
    const navgate = useNavigate();

    useEffect(() => {
        if (userData) {
            setUserName((userData.FULLNAME && userData.FULLNAME !== 'unknown' && userData.FULLNAME !== '') ? userData.FULLNAME : getName(userData.EMAIL));
            setUserType(userData.USER_TYPE);
            setProfileKey(userData.USER_TYPE ? userData.OBJID : userData.OBJID);
            setIsResident(userData.USER_TYPE === "RESIDENT");
        }else{
            navgate('/home')
        }

    }, [userData]);

    const getName = (email) => {
        let name = email.split('@')[0];
        name = name.replace(/\.|_/g, ' ');
        if (name.length > appConfig.maxUserNameLength) {
            name = name.substring(0, appConfig.maxUserNameLength) + '...';
        }
        return name;
    }

    return (<>
        {!isResident ?
            <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
                <MDBDropdown>
                    <MDBDropdownToggle tag="a" href="#!" className="hidden-arrow nav-link">
                        <MDBRipple rippleColor='light' className='nav-user' children="div">
                            <MDBIcon icon='circle-user' far size='2x' className='user-icon text-dark' />
                            <div className='user-info'>
                                <div className='user-name'>{userName}</div>
                                <div className='user-job'>{userType}</div>
                            </div>
                            <MDBIcon icon='angle-down' className='drop-handle text-dark' />
                        </MDBRipple>
                    </MDBDropdownToggle>

                    <MDBDropdownMenu className='border border-2 user-dd-menu user-tray'>

                        <MDBDropdownItem >
                        <Link to={`/app/profile/${profileKey}`} className='text-dark'>
                            <MDBRipple rippleColor='dark' children="div">
                                {/* <Link className='text-dark'> */}
                                    <MDBIcon icon='user' fas className='me-2' />
                                    My profile
                                {/* </Link> */}

                            </MDBRipple>
                            </Link>
                        </MDBDropdownItem>

                        {/*{userData.USER_TYPE !== "RESIDENT" && */}
                        <MDBDropdownItem className='border-top'>
                            <MDBRipple rippleColor='dark' children="div">
                                <Link to='/app/changepwd' className='text-dark'>
                                    <MDBIcon icon='key' fas className='me-2' />
                                    Change password
                                </Link>
                            </MDBRipple>
                        </MDBDropdownItem>
                        {/* } */}
                        <MDBDropdownItem link className='border-top'>
                            <MDBRipple rippleColor='danger' children="div">
                                <Link to='/app/logout' className='text-dark' onClick={handleLogoutClick}>
                                    <MDBIcon icon='power-off' fas className='me-2 text-danger' />
                                    {userData.USER_TYPE === "RESIDENT" ? 'Exit' : 'Log out'}
                                </Link>
                            </MDBRipple>
                        </MDBDropdownItem>

                    </MDBDropdownMenu>
                </MDBDropdown >
            </MDBNavbarItem>
            : <MDBNavbarItem className='me-3 me-lg-0 d-flex align-items-center'>
                     
                            {userData.USER_TYPE === "CBO" ? <div className='user-info'>
                            <MDBIcon icon='circle-user' far size='2x' className='user-icon text-dark' />
                                <div className='user-name'>{userName}</div>
                                <div className='user-job'>{userType}</div>
                            </div>
                            
                            : <div className='user-info-res'>
                                <div className='user-name'>
                                <Link to='/app/logout' className='text-res' onClick={handleLogoutClick}>
                                    <i className="fas fa-angle-left"></i>
                                    {userData? userData.USER_TYPE === "RESIDENT" ? 'Back to Home' : 'Log out' : 'Log out'}
                                </Link>
                                </div>
                                {/* <div className='user-job'>{userType}</div> */}
                            </div>}

                            {/* <MDBIcon icon='angle-down' className='drop-handle text-dark' /> */}
                       

                    {/* <MDBDropdownMenu className='border border-2 user-dd-menu user-tray'>
                        <MDBDropdownItem className='border-top'>
                            <MDBRipple rippleColor='danger' children="div">
                                <Link to='/app/logout' className='text-dark'>
                                    <MDBIcon icon='power-off' fas className='me-2 text-danger' />
                                    {userData? userData.USER_TYPE === "RESIDENT" ? 'Exit' : 'Log out' : 'Log out'}
                                </Link>
                            </MDBRipple>
                        </MDBDropdownItem>

                    </MDBDropdownMenu> */}
                
            </MDBNavbarItem>
        }
    </>)
}

export default UserTray